import { remove } from 'lodash'
import {
  getReview,
  getPriceAsc,
  getPriceDesc,
  getRecommend,
  getMostPopular,
  getNewArrivals,
  isRecProductsPage
} from './utils'

export const cardConfigDefault = {
  'configReady': true,
  'showBadge': true,
  'showAddBagAfterGoodsPrice': true,
  'colorSetPresentedType': 'ProColorB',
  'showMultiColor': true,
  'showPromotion': true,
  'showPlusSize': true,
  'showSeriesBrand': true,
  'showLocalSeller': true,
  'showPromoLabel': true,
  'showUserBehaviorLabel': true,
  'showUserPreferencesLabel': true,
  'showUserCommentKeywordsLabel': true,
  'showBeltLabel': true,
  'showRankingList': true,
  'autoCropImage': true,
  'showAddBagBtn': true,
  'hideRetailPrice': true,
  'hideDiscountBadge': true,
  'hideGoodsNum': true,
  'showCartBag': true,
  'showStarComment': true,
  'showTitle': true,
  'showSellingPoint': true,
  'showFeedback': true,
  'showSpuImg': true,
  'showNewFlashPriceOnSale': true,
  'showNewFlashLabelUpPrice': true,
  'showQuickShip': true,
  'showDiscountLabelAfterPrice': true,
  'showBestDealLabel': true,
  'showLocateLabels': 'default',
  'showHotLocals': true,
  'showDeliveryWords': true,
  'rankingLabelClickable': true,
  'showPriceDiscountDesc': true,
  'hideFlashSaleCountDown': true,
  'showPropertyUpsell': true,
  'showEstimatedPriceOnSale': true,
  'showNewFlashNewLabel': true,
  'showSelectOnLongClick': true
}

const getters = {
  sortAllList(state) {
    const language = state?.Language || {}
    const { pageName } = state?.Results?.cat_info || {}

    const list =  [
      getRecommend(language),
      getMostPopular(language, pageName),
      getNewArrivals(language, pageName),
      getReview(language),
      getPriceAsc(language, pageName),
      getPriceDesc(language, pageName),
    ]

    // 信息流落地页和实时反馈落地页没有
    if (isRecProductsPage({ pageName })) {
      remove(list, (item) => item.label_en === 'Review')
    }

    return list
  },
  sortMap(state) {
    const language = state?.Language || {}
    const isShowPriceBar =
      state?.ComponentState?.FilterBar?.filterConfig?.isShowPriceBar || false
    const isShowMostPopularInSort =
      state?.ComponentState?.FilterBar?.filterConfig?.isShowMostPopularInSort ||
      false
    const { pageName } = state?.Results?.cat_info || {}

    const list = [
      getRecommend(language),
      getMostPopular(language, pageName),
      getNewArrivals(language, pageName),
      getReview(language),
      getPriceAsc(language, pageName),
      getPriceDesc(language, pageName),
    ]

    // 当前是否信息流落地页列表 或 实时反馈落地页
    const isPickedInfoPage = isRecProductsPage({ pageName })
    
    if(isPickedInfoPage) {
      remove(list, (item) => item.label_en === 'Review')
    }

    // 排序外露时隐藏 Most Popular
    (!isShowMostPopularInSort) &&  remove(list, (item) => item.label_en === 'Most Popular')
    
    // 排序外露时隐藏 Price Low To High 和 Price High To Low
    isShowPriceBar && remove(list, (item) =>/price/i.test(item.label_en))
    
    return list
  },
  sortMap2(state) {
    const language = state?.Language || {}
    const isShowMostPopularInSort =
      state?.ComponentState?.FilterBar?.filterConfig?.isShowMostPopularInSort ||
      false
    const { pageName } = state?.Results?.cat_info || {}

    const list = [
      getRecommend(language),
      getMostPopular(language, pageName),
      getPriceAsc(language, pageName),
      getPriceDesc(language, pageName),
      getNewArrivals(language, pageName),
      getReview(language),
    ]

    // 当前是否信息流落地页列表 或 实时反馈落地页
    const isPickedInfoPage = isRecProductsPage({ pageName })
    isPickedInfoPage && remove(list, (item) => item.label_en === 'Review')
    
    // 实验控制是否展示 Most Popular
    if (!isShowMostPopularInSort) {
      remove(list, (item) => item.label_en === 'Most Popular')
    }

    return list
  },
  /**
   * new data below ↓
   */
  // S - 基础配置
  locals(state) {
    return state.Locals || {}
  },
  language(state) {
    return state.Language || {}
  },
  listAbtResult(state) {
    return state.Results.listAbtResult || {}
  },
  cccSeo: (state) => {
    return state.Results.cccSeo || {}
  },
  cccOperation: (state) => {
    return state.Results.cccOperation || {}
  },
  cccxConfig: (state) => {
    return state.Results.cccxConfig || {}
  },
  cccxFilterConfig: (state) => {
    return state.Results.cccxFilterConfig || {}
  },
  shouldUseCccxFilterChain: state => {
    return state.Results.shouldUseCccxFilterChain || false
  },
  mallInfo: (state) => {
    return state.Results.mallInfo || []
  },
  cccConfig: (state) => {
    return state?.Results?.cccConfig || {}
  },
  // 会员相关信息，注意：为undefined或者null
  sheinClubInfo: (state) => {
    return state?.Results?.sheinClubInfo || {}
  },
  // S - 页面信息
  catInfo: (state) => {
    return state.Results.cat_info || {}
  },
  currentCat: (state) => {
    return state.Results.currentCat || {}
  },
  parentCats: (state) => {
    return state.Results.parentCats || {}
  },
  searchKeywords: (state) => {
    return state.Results.searchKeywords || {}
  },
  searchCCCCard: (state) => {
    return state.Results.searchBannerCard?.searchCCCCard  || null
  },
  searchStoreCard: (state) => {
    return state.Results.searchBannerCard?.searchStoreCard  || null
  },
  searchTrendCard: (state) => {
    return state.Results.searchBannerCard?.searchTrendCard  || null
  },
  // S - 商品数据
  sum: (state) => {
    return state.Results.sum || 0
  },
  sumForPage: (state) => {
    return state.Results.sumForPage || 0
  },
  goods: (state) => {
    return state.Results.goods || []
  },
  appendGoods: (state) => {
    return state.Results.appendGoods || []
  },
  // S - 筛选数据
  picTopNav: (state) => {
    return state.Results?.picTopNav?.length 
      ? state.Results.picTopNav
      : []
  },
  picTopNavCate: (state) => {
    return state.Results.picTopNavCate || []
  },
  cloudTags: (state) => {
    return state.Results.cloudTags || []
  },
  bannerTag: (state) => {
    return state.Results.bannerTag || {}
  },
  dailyDates: (state) => {
    return state.Results.dailyDates || {}
  },
  filterCates: (state) => {
    return state.Results.filterCates || []
  },
  filterTsps: (state) => {
    return state.Results.filterTsps || []
  },
  filterAttrs: (state) => {
    return state.Results.filterAttrs || []
  },
  filterPrices: (state) => {
    return state.Results.filterPrices || {}
  },
  // S - 实验跟踪
  tracking: (state) => {
    return state.Results.tracking || {}
  },
  request_ext: (state) => {
    return state.Results.request_ext || {}
  },
  goodsCrowId: (state) => {
    return state.Results.goodsCrowId || {}
  },
  fhContext: (state) => {
    return state.Results.fhContext || {}
  },
  googleContext: (state) => {
    return state.Results.googleContext || {}
  },
  listFilterAbt: (state) => {
    return state.Results?.filterAbtParam?.abt || {}
  },
  promotionInfoFromServer: (state) => {
    return state.Results.promotionInfoFromServer || {}
  },
  atomicParams: (state) => {
    return state.Results.atomicParams || {}
  },
  cateCoupon: (state) => {
    return state?.ComponentState?.ProductList?.cateCoupon || {
      Data: {},
      Abt: {}
    }
  },
  couponLanguage(state) {
    return state?.ComponentState?.ProductList.couponLanguage
  },
  policyBanner(state) {
    return state.Results.policyBanner || {}
  },
  apolloConfig(state) {
    return state.Results.apolloConfig || {}
  },
  flowMap(state) {
    return state?.ComponentState?.ProductList?.flowMap ?? {}
  },
  discountPageInfo(state) {
    return state.Results.discountPageInfo || {}
  },
  cardConfig(state) {
    const cardConfig = state.Results.cardConfig

    return cardConfig || {}
  }
}
export default getters
